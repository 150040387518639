import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/bookPageTemplate.js";
import Quote from "../../components/text-decorations/quote";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "how-to-program-deliberately",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-program-deliberately",
        "aria-label": "how to program deliberately permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to program Deliberately`}</h2>
    <p>{`Programming deliberately allows you to catch, fix errors early and helps you write better code. By programming deliberately you should:`}</p>
    <Quote mdxType="Quote">
  <ul>
    <li> Always be aware of what you are doing.</li>
    <li> (...) explain the code, in detail</li>
    <li>
      {" "}
      Don't code in the dark. Build an application you don't fully grasp, or use
      a technology you don't understand.
    </li>
    <li> Proceed from a plan (...)</li>
    <li> Rely only on reliable things. Don't depend on assumptions.</li>
    <li> Document your assumptions</li>
    <li>
      {" "}
      Don't just test your code, but test your assumptions as well. Don't guess;
    </li>
    <li> Prioritize your effort. Spend time on important aspects.</li>
    <li>
      {" "}
      (...) Don't let existing code dictate future core. All code can be replaced
      if its no longer appropriate.
    </li>
  </ul>
    </Quote>
    <h2 {...{
      "id": "scoping-and-planning",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#scoping-and-planning",
        "aria-label": "scoping and planning permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Scoping and planning`}</h2>
    <Quote mdxType="Quote">
  That's what we do. When given something that seems simple, we annoy people by
  looking for edge cases and asking about them.
    </Quote>
    <Quote mdxType="Quote">
  Another big danger in producing a requirements document is being too specific.
    </Quote>
    <Quote mdxType="Quote">
  Requirements are not architecture. Requirements are not design, nor are they
  the user interface. Requirements are need.
    </Quote>
    <Quote mdxType="Quote">
  These short descriptions are often called user stories. They describe what a
  small portion of the application should do from the perspective of a user of
  that functionality.
    </Quote>
    <h2 {...{
      "id": "building-a-knowledge-portfolio",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#building-a-knowledge-portfolio",
        "aria-label": "building a knowledge portfolio permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Building a knowledge portfolio`}</h2>
    <Quote mdxType="Quote">
  Your knowledge and experience are your most import day-to-day professional
  assets.
    </Quote>
    <p>{`Tips on how to improve your knowledge:`}</p>
    <Quote mdxType="Quote">Learn at least one new language every year</Quote>
    <Quote mdxType="Quote">Read a technical book each month</Quote>
    <Quote mdxType="Quote">Read nontechnical books too</Quote>
    <Quote mdxType="Quote">Take classes</Quote>
    <Quote mdxType="Quote">Experiment with different environments</Quote>
    <Quote mdxType="Quote">Stay current</Quote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      